import ApiService from "@/core/services/api.service";
import { LOGOUT } from "@/core/services/store/auth.module";
import JwtService from "@/core/services/jwt.service";
import { SET_SNACK } from "@/core/services/store/snackbar.module";

// action types
export const LISTINGS_LIST = "listings_list";
export const CREATE_LISTING = "create_listing";
export const UPDATE_LISTING = "update_listing";
export const GET_LISTING_DETAILS = "get_lsiting_details";
export const DELETE_LISTING = "delete_listing";
export const UPLOAD_LISTING_IMAGE = "upload_listing_image";
export const DELETE_UPLOADED_LISTING_FILE = "delete_uploaded_listing_file";
export const DELETE_UPLOADED_LISTING_TAG = "delete_uploaded_listing_tag";
export const CLEAR_LISTING_ERRORS = "clearListingErrors";

// mutation types
export const SET_LISTING_ERRORS = "setListingError";
export const REMOVE_LISTING_ERRORS = "removeListingErrors";

const state = {
  errors: null
};

const actions = {
  [LISTINGS_LIST](context, payload) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return new Promise(resolve => {
        ApiService.datatable(
          "api/listings?" + payload.q + payload.columns.join("")
        )
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            // console.log(response);
            context.commit(SET_LISTING_ERRORS, response.errors);
          });
      });
    } else {
      this.$store.dispatch(LOGOUT);
    }
  },
  [CREATE_LISTING](context, payload) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return new Promise(resolve => {
        return ApiService.post("api/createlisting", payload.data)
          .then(({ data }) => {
            if (data._metadata.outcomeCode === 0) {
              this.dispatch(SET_SNACK, {
                clr: "success",
                msg: data._metadata.message
              });
              resolve(data);
            } else {
              this.dispatch(SET_SNACK, {
                clr: "error",
                msg: data._metadata.message
              });
              context.commit(SET_LISTING_ERRORS, data.errors);
            }
          })
          .catch(({ response }) => {
            // console.log(errors);
            context.commit(SET_LISTING_ERRORS, response.errors);
          });
      });
    } else {
      this.$store.dispatch(LOGOUT);
    }
  },
  [UPDATE_LISTING](context, payload) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return new Promise(resolve => {
        return ApiService.update(
          "api/updatelisting",
          payload.slug,
          payload.data
        )
          .then(({ data }) => {
            if (data._metadata.outcomeCode === 0) {
              this.dispatch(SET_SNACK, {
                clr: "success",
                msg: data._metadata.message
              });
              resolve(data);
            } else {
              this.dispatch(SET_SNACK, {
                clr: "error",
                msg: data._metadata.message
              });
              context.commit(SET_LISTING_ERRORS, data.errors);
            }
          })
          .catch(({ response }) => {
            context.commit(SET_LISTING_ERRORS, response.errors);
          });
      });
    } else {
      this.$store.dispatch(LOGOUT);
    }
  },
  [GET_LISTING_DETAILS](context, id) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return new Promise(resolve => {
        ApiService.get("api/getlistingdetail", id)
          .then(({ data }) => {
            if (data._metadata.outcomeCode === 0) {
              resolve(data);
            } else {
              this.dispatch(SET_SNACK, {
                clr: "error",
                msg: data._metadata.message
              });
              context.commit(SET_LISTING_ERRORS, data.errors);
            }
          })
          .catch(({ response }) => {
            // console.log(response);
            context.commit(SET_LISTING_ERRORS, response.errors);
          });
      });
    } else {
      this.$store.dispatch(LOGOUT);
    }
  },
  [DELETE_LISTING](context, payload) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return new Promise(resolve => {
        return ApiService.post("api/deletelisting", payload)
          .then(({ data }) => {
            if (data._metadata.outcomeCode === 0) {
              this.dispatch(SET_SNACK, {
                clr: "success",
                msg: data._metadata.message
              });
              resolve(data);
            } else {
              this.dispatch(SET_SNACK, {
                clr: "error",
                msg: data._metadata.message
              });
              context.commit(SET_LISTING_ERRORS, data.errors);
            }
          })
          .catch(({ response }) => {
            context.commit(SET_LISTING_ERRORS, response.errors);
          });
      });
    } else {
      this.$store.dispatch(LOGOUT);
    }
  },
  [UPLOAD_LISTING_IMAGE](context, payload) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return new Promise(resolve => {
        return ApiService.post("api/uploadlistingfile", payload)
          .then(({ data }) => {
            if (data._metadata.outcomeCode === 0) {
              this.dispatch(SET_SNACK, {
                clr: "success",
                msg: data._metadata.message
              });
              resolve(data);
            } else {
              this.dispatch(SET_SNACK, {
                clr: "error",
                msg: data._metadata.message
              });
              context.commit(SET_LISTING_ERRORS, data.errors);
            }
          })
          .catch(({ response }) => {
            context.commit(SET_LISTING_ERRORS, response.errors);
          });
      });
    } else {
      this.$store.dispatch(LOGOUT);
    }
  },
  [DELETE_UPLOADED_LISTING_FILE](context, payload) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return new Promise(resolve => {
        return ApiService.post("api/deleteuploadedlistingfile", payload)
          .then(({ data }) => {
            if (data._metadata.outcomeCode === 0) {
              this.dispatch(SET_SNACK, {
                clr: "success",
                msg: data._metadata.message
              });
              resolve(data);
            } else {
              this.dispatch(SET_SNACK, {
                clr: "error",
                msg: data._metadata.message
              });
              context.commit(SET_LISTING_ERRORS, data.errors);
            }
          })
          .catch(({ response }) => {
            context.commit(SET_LISTING_ERRORS, response.errors);
          });
      });
    } else {
      this.$store.dispatch(LOGOUT);
    }
  },
  [DELETE_UPLOADED_LISTING_TAG](context, payload) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return new Promise(resolve => {
        return ApiService.post("api/deleteuploadedlistingtag", payload)
          .then(({ data }) => {
            if (data._metadata.outcomeCode === 0) {
              this.dispatch(SET_SNACK, {
                clr: "success",
                msg: data._metadata.message
              });
              resolve(data);
            } else {
              this.dispatch(SET_SNACK, {
                clr: "error",
                msg: data._metadata.message
              });
              context.commit(SET_LISTING_ERRORS, data.errors);
            }
          })
          .catch(({ response }) => {
            context.commit(SET_LISTING_ERRORS, response.errors);
          });
      });
    } else {
      this.$store.dispatch(LOGOUT);
    }
  },
  [CLEAR_LISTING_ERRORS](context) {
    context.commit(REMOVE_LISTING_ERRORS);
  }
};

const mutations = {
  [SET_LISTING_ERRORS](state, error) {
    state.errors = error;
  },
  [REMOVE_LISTING_ERRORS](state) {
    state.errors = {};
  }
};

export default {
  state,
  actions,
  mutations
};
