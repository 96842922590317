<template>
  <v-card flat>
    <v-card-text>
      <v-row>
        <v-col cols="12">
          <v-row>
            <v-col
              cols="4"
              class="uploaded-files"
              v-for="video in uploadedVideosOld"
              :key="video.id"
            >
              <a :href="video.url" target="_blank">{{ video.url }}</a>
              <v-btn
                dark
                x-small
                color="red"
                @click="removeUploadedVideoOld(video)"
              >
                <v-icon dark>
                  fas fa-times
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12">
          <div class="form-group" v-for="(input, k) in inputs" :key="k">
            <v-text-field
              label="Video Link"
              color="primary"
              maxlength="100"
              :counter="100"
              @keyup="update"
              v-model="input.url"
            >
              <template v-slot:append>
                <v-btn
                  @click="remove(k)"
                  v-show="k || (!k && inputs.length > 1)"
                  small
                  dark
                  color="red"
                  class="mr-1"
                >
                  <v-icon small dark>fas fa-minus-circle</v-icon>
                </v-btn>
                <v-btn
                  @click="add(k)"
                  v-show="k == inputs.length - 1"
                  small
                  color="success"
                  class="ma-0"
                >
                  <v-icon small dark>fas fa-plus-circle</v-icon>
                </v-btn>
              </template>
            </v-text-field>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { bus } from "@/main";
import { DELETE_UPLOADED_LISTING_FILE } from "@/core/services/store/listings.module";

export default {
  name: "listing-videos",
  props: ["listingVideosOld", "listingVideosInput"],
  data() {
    return {
      uploadedVideosOld: [],
      inputs: [
        {
          url: ""
        }
      ]
    };
  },
  watch: {
    listingVideosOld: {
      handler() {
        this.uploadedVideosOld = this.listingVideosOld;
      },
      deep: true
    },
    listingVideosInput: {
      handler() {
        this.inputs = this.listingVideosInput;
      },
      deep: true
    }
  },
  mounted() {
    this.uploadedVideosOld = this.listingVideosOld;
    bus.$emit("listingVideosUploaded", this.inputs);
  },
  methods: {
    add() {
      this.inputs.push({ url: "" });
      bus.$emit("listingVideosUploaded", this.inputs);
    },
    update() {
      bus.$emit("listingVideosUploaded", this.inputs);
    },
    remove(index) {
      this.inputs.splice(index, 1);
      bus.$emit("listingVideosUploaded", this.inputs);
    },
    removeUploadedVideoOld(video) {
      let that = this;
      const index = this.uploadedVideosOld.findIndex(element => {
        if (element.path === video.path) {
          return true;
        }
      });
      if (index > -1) {
        this.$store
          .dispatch(DELETE_UPLOADED_LISTING_FILE, {
            files: video,
            type: "video"
          })
          .then(() => {
            that.uploadedVideosOld.splice(index, 1);
            bus.$emit("listingVideosUploadedOld", this.uploadedVideosOld);
          });
      }
    }
  }
};
</script>
