<template>
  <v-card flat>
    <v-card-text>
      <v-row>
        <v-col cols="12">
          <v-row>
            <v-col
              cols="3"
              class="uploaded-files"
              v-for="fle in uploadedFilesOld"
              :key="fle.id"
            >
              <a :href="fle.listing_file_public_url" target="_blank">{{
                fle.listing_file_public_url
              }}</a>
              <v-btn
                dark
                x-small
                color="red"
                @click="removeUploadedFileOld(fle)"
              >
                <v-icon dark>
                  fas fa-times
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="6">
          <v-btn
            color="primary"
            dark
            class="ml-auto ma-3"
            style="width:100%"
            @click="uploadFiles"
            v-if="isFilesLoaded"
          >
            Upload files
            <v-icon class="ml-2" small>fas fa-upload</v-icon>
          </v-btn>
          <file-pond
            name="files"
            ref="pond"
            label-idle="Drop files here or <span class='filepond--label-action'>Browse</span>"
            allow-multiple="true"
            accepted-file-types="application/pdf, application/msword"
            v-model="files"
            @init="handleFilePondInit"
            @addfile="handleFileAdded"
            @removefile="handleFileRemoved"
          />
        </v-col>
        <v-col cols="6" sm>
          <v-row>
            <v-col
              cols="6"
              class="uploaded-files"
              v-for="file in uploadedFiles"
              :key="file.id"
            >
              <p>{{ file.url }}</p>
              <v-btn dark x-small color="red" @click="removeUploadedFile(file)">
                <v-icon dark>
                  fas fa-times
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { bus } from "@/main";
// Import Vue FilePond
import vueFilePond from "vue-filepond";

// Import FilePond styles
import "filepond/dist/filepond.min.css";

// Import file type validation plugins
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import {
  CLEAR_LISTING_ERRORS,
  DELETE_UPLOADED_LISTING_FILE,
  UPLOAD_LISTING_IMAGE
} from "@/core/services/store/listings.module";

// Create component
const FilePond = vueFilePond(FilePondPluginFileValidateType);
export default {
  name: "listing-files",
  props: ["listingFilesOld"],
  data() {
    return {
      files: [],
      uploadedFiles: [],
      uploadedFilesOld: [],
      isFilesLoaded: false
    };
  },
  watch: {
    listingFilesOld: {
      handler() {
        this.uploadedFilesOld = this.listingFilesOld;
      },
      deep: true
    }
  },
  mounted() {
    this.uploadedFilesOld = this.listingFilesOld;
    bus.$on("listingFilesDeletedByModal", () => {
      this.files = [];
      this.uploadedFiles = [];
      this.isFilesLoaded = false;
    });
    // bus.$on('listingModalClosed', () => {
    //     this.$refs.pond.removeFiles();
    // });
    // bus.$on('editModalTriggered', () => {
    //   console.log(123);
    //   // this.uploadedImagesOld = listingImages;
    // });
  },
  methods: {
    handleFileAdded() {
      this.isFilesLoaded = true;
      // const formData = new FormData();
      // formData.append('file', f.file, f.file.name);
      // formData.append('type', 'image');
      // this.uploadImage(formData);
    },
    handleFileRemoved() {
      if (this.files.length === 0) {
        this.isfilesLoaded = false;
      }
      // const formData = new FormData();
      // formData.append('file', f.file, f.file.name);
      // formData.append('type', 'image');
      // this.uploadImage(formData);
    },
    uploadFiles() {
      const formData = new FormData();
      this.files.forEach(v => {
        formData.append("files[]", v.file, v.file.name);
      });
      formData.append("type", "file");
      this.$store.dispatch(CLEAR_LISTING_ERRORS);
      this.$store.dispatch(UPLOAD_LISTING_IMAGE, formData).then(data => {
        data.records.forEach(v => {
          this.uploadedFiles.push(v);
        });
        this.$refs.pond.removeFiles();
        this.isFilesLoaded = false;
        bus.$emit("listingFilesUploaded", this.uploadedFiles);
      });
    },
    removeUploadedFile(file) {
      let that = this;
      const index = this.uploadedFiles.findIndex(element => {
        if (element.path === file.path) {
          return true;
        }
      });
      if (index > -1) {
        this.$store
          .dispatch(DELETE_UPLOADED_LISTING_FILE, { files: file, type: "temp" })
          .then(() => {
            that.uploadedFiles.splice(index, 1);
            bus.$emit("listingFilesUploaded", this.uploadedFiles);
          });
      }
    },
    removeUploadedFileOld(file) {
      let that = this;
      const index = this.uploadedFilesOld.findIndex(element => {
        if (element.path === file.path) {
          return true;
        }
      });
      if (index > -1) {
        this.$store
          .dispatch(DELETE_UPLOADED_LISTING_FILE, { files: file, type: "temp" })
          .then(() => {
            that.uploadedFilesOld.splice(index, 1);
            bus.$emit("listingFilesUploadedOld", this.uploadedFilesOld);
          });
      }
    },
    handleFilePondInit() {
      // console.log('FilePond has initialized');
      // FilePond instance methods are available on `this.$refs.pond`
    }
  },
  components: {
    FilePond
  }
};
</script>
